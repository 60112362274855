import { MarginConstantType } from '@/types/margin';
import { PaddingConstantType } from '@/types/padding';
import { PREFIX_MARGIN, PREFIX_PADDING } from '@/utils/prefix';

export const createClass = (
  prefix: string,
  body: string,
  postfix: string = ''
) => (body ? `${prefix}${body}${postfix}` : '');

export const getSpaceVerticalPostfix = (values: number[]) => {
  const value = values?.[0] === undefined ? 0 : values?.[0];
  return `-space-${value}`;
};

export const getSpaceHorizontalPostfix = (values: number[]) => {
  const oneArgument = values?.[0] === undefined ? 0 : values?.[0];

  const value = values?.[1] === undefined ? oneArgument : values?.[1];

  return `-space-${value}`;
};

export const getMarginPaddingClass = (
  padding: PaddingConstantType,
  margin: MarginConstantType
) => {
  return `
    ${createClass(PREFIX_MARGIN, 'v', `${getSpaceVerticalPostfix(margin)}`)}
    ${createClass(PREFIX_MARGIN, 'h', `${getSpaceHorizontalPostfix(margin)}`)}
    ${createClass(PREFIX_PADDING, 'v', `${getSpaceVerticalPostfix(padding)}`)}
    ${createClass(
      PREFIX_PADDING,
      'h',
      `${getSpaceHorizontalPostfix(padding)}`
    )} `;
};

export const getKebabCase = (str = '') =>
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    ?.map((x) => x.toLocaleLowerCase())
    .join('-');

export const mapObjectToStringWithKebabCaseKey = (object: {
  [key: string]: string;
}) => {
  let string = ``;
  Object.keys(object).forEach((key: string) => {
    string += `${getKebabCase(key)}:${object[key]}; `;
  });

  return string;
};
