export const TYPOGRAPHY_PREFIX = 'typo--';

export const TYPOGRAPHY_BLOG_PREFIX = 'typo-blog--';

export const PREFIX_TEXT_COLOR = 'text-color--';

export const PREFIX_PADDING = 'padding--';

export const PREFIX_MARGIN = 'margin--';

export const PREFIX_FONT_SIZE = 'font-size--';

export const PREFIX_LINE_HEIGHT = 'line-height--';

export const PREFIX_BACKGROUND_COLOR = 'background-color--';
