import { memo } from 'react';
import { Link } from 'gatsby';
import { ButtonOutlineType, ButtonType } from '@/components/Button/types';
import {
  MAIN_CLASS,
  PREFIX_BACKGROUND,
  PREFIX_BORDER_RADIUS,
  PREFIX_OUTLINE,
  PREFIX_TEXT_TRANSFORM
} from '@/components/Button/utils';
import {
  createClass,
  getSpaceHorizontalPostfix,
  getSpaceVerticalPostfix
} from '@/helpers';
import {
  PREFIX_FONT_SIZE,
  PREFIX_MARGIN,
  PREFIX_PADDING,
  PREFIX_TEXT_COLOR,
  TYPOGRAPHY_PREFIX
} from '@/utils/prefix';
import './Button.scss';

export function Button({ ...props }: ButtonType): JSX.Element;

export function Button({ ...props }: ButtonOutlineType): JSX.Element;

export function Button({
  children,
  color = 'primary',
  outlineColor,
  fontSize = 20,
  padding = [4, 6], // number from 1 to 64 first number for vertical second horizontal, it's possible to add one value f.e [1]
  margin = [0], // same like padding
  borderRadius = 100,
  textColor = 'default',
  typography = 'text-primary-large-bold',
  type = 'button',
  additionalClass = '',
  isTextUppercase = true,
  link = '',
  isExternalLink = false,
  disabled = false,
  unpacked = false,
  onClickFunc
}: Partial<ButtonType & ButtonOutlineType>) {
  const cssClass = `
        position-relative
        ${MAIN_CLASS}
        ${
          isTextUppercase ? createClass(PREFIX_TEXT_TRANSFORM, 'uppercase') : ''
        }
        ${outlineColor ? '' : createClass(PREFIX_BACKGROUND, color)}
        ${outlineColor ? createClass(PREFIX_OUTLINE, outlineColor) : ''}
        ${createClass(PREFIX_MARGIN, 'v', `${getSpaceVerticalPostfix(margin)}`)}
        ${createClass(
          PREFIX_MARGIN,
          'h',
          `${getSpaceHorizontalPostfix(margin)}`
        )}
        ${createClass(
          PREFIX_PADDING,
          'v',
          `${getSpaceVerticalPostfix(padding)}`
        )}
        ${createClass(
          PREFIX_PADDING,
          'h',
          `${getSpaceHorizontalPostfix(padding)}`
        )}
        ${createClass(PREFIX_BORDER_RADIUS, `${borderRadius}`)}
        ${createClass(PREFIX_FONT_SIZE, `${fontSize}`)}
        ${createClass(PREFIX_TEXT_COLOR, textColor)}
        ${createClass(TYPOGRAPHY_PREFIX, typography)}
        ${additionalClass}`;

  const getRedirection = () => {
    if (isExternalLink) {
      return (
        <a
          className="button-link"
          href={link}
          target={'_blank'}
          rel="noreferrer"
        />
      );
    }

    return <Link className="button-link" to={link} />;
  };

  return (
    <>
      {unpacked ? (
        <>
          <button
            className={cssClass}
            onClick={onClickFunc}
            type={type}
            disabled={disabled}
          >
            {children}
            {link ? getRedirection() : ''}
          </button>
        </>
      ) : (
        <div>
          <button
            className={cssClass}
            onClick={onClickFunc}
            type={type}
            disabled={disabled}
          >
            {children}
            {link ? getRedirection() : ''}
          </button>
        </div>
      )}
    </>
  );
}

export default memo(Button);
