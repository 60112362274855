import { ImgHTMLAttributes, ReactNode } from 'react';
import Blackquote from '@/components/Blockqote';
import CodeBlock from '@/components/CodeBlock';
import { Typography } from '@/components/Typography';

const Components = {
  h3: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="h3"
      blogTypography
      typographyKind={'header-secondary-medium-bold'}
      margin={[8, 0]}
      {...props}
    />
  ),
  h4: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="h4"
      blogTypography
      typographyKind={'header-secondary-small-bold'}
      margin={[8, 0]}
      {...props}
    />
  ),
  h5: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="h5"
      blogTypography
      typographyKind={'header-secondary-extra-small-bold'}
      margin={[8, 0]}
      {...props}
    />
  ),
  p: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="div"
      blogTypography
      typographyKind={'text-secondary-extra-large'}
      margin={[8, 0]}
      {...props}
    />
  ),
  a: (props: JSX.IntrinsicAttributes & { href: string }) => (
    <Typography
      component="a"
      blogTypography
      typographyKind={'text-secondary-extra-large-medium'}
      margin={[8, 0]}
      link={props.href}
      {...props}
    />
  ),
  strong: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="strong"
      blogTypography
      typographyKind={'text-secondary-extra-large-bold'}
      margin={[8, 0]}
      {...props}
    />
  ),
  b: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="b"
      blogTypography
      typographyKind={'text-secondary-extra-large-bold'}
      margin={[8, 0]}
      {...props}
    />
  ),
  li: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="li"
      blogTypography
      typographyKind={'text-secondary-extra-large'}
      margin={[2, 0]}
      {...props}
    />
  ),
  img: (props: ImgHTMLAttributes<HTMLImageElement>) => <img {...props} />,
  figcaption: (props: JSX.IntrinsicAttributes) => (
    <Typography
      component="figcaption"
      blogTypography
      typographyKind={'text-secondary-extra-small'}
      color={'secondary-thirty'}
      {...props}
      margin={[4, 0]}
    />
  ),
  pre: (
    props: JSX.IntrinsicAttributes & {
      children: {
        props: { className: string; children: string; highlights: any };
      };
    }
  ) => <CodeBlock {...props} />,
  wrapper: ({ children }: { children: ReactNode }) => <>{children}</>,
  blockquote: Blackquote
};

export default Components;
