import { memo } from 'react';
import { Link } from 'gatsby';
import {
  AllBlogTypography,
  AllTypography,
  H1TagType,
  H1TypographyType,
  H2H6TypographyType,
  H2HTagType,
  HeaderBlogTagType,
  HeaderBlogTypographyType,
  TextBlogTypographyType,
  TextTagType,
  TextTypographyType,
  TypographyBlogType,
  TypographyTags,
  TypographyType
} from '@/components/Typography/types';
import {
  createClass,
  getSpaceHorizontalPostfix,
  getSpaceVerticalPostfix
} from '@/helpers';
import {
  PREFIX_FONT_SIZE,
  PREFIX_LINE_HEIGHT,
  PREFIX_MARGIN,
  PREFIX_TEXT_COLOR,
  TYPOGRAPHY_PREFIX,
  TYPOGRAPHY_BLOG_PREFIX
} from '@/utils/prefix';

import './Typography.scss';

// Typography get two required inputs (component, typography).
// Component is tag, typography is font style,
// for h1 use TypographyHeaderPrimaryType
// for h2-h6 use TypographyHeaderSecondaryType
// for p, span use TypographyTextPrimaryType and TypographyTextSecondaryType

export function Typography({
  ...props
}: TypographyType<H1TagType, H1TypographyType>): JSX.Element;

export function Typography({
  ...props
}: TypographyType<H2HTagType, H2H6TypographyType>): JSX.Element;

export function Typography({
  ...props
}: TypographyType<TextTagType, TextTypographyType>): JSX.Element;

export function Typography({
  ...props
}: TypographyBlogType<
  HeaderBlogTagType,
  HeaderBlogTypographyType
>): JSX.Element;

export function Typography({
  ...props
}: TypographyBlogType<TextTagType, TextBlogTypographyType>): JSX.Element;

export function Typography({
  id = '',
  component,
  children,
  typographyKind,
  blogTypography = false,
  className = '',
  color = 'primary',
  margin = [0], // number from 1 to 64 first number for vertical second horizontal, it's possible to add one value f.e [1]
  size,
  lineHeight,
  link = '',
  isExternalLink = false
}:
  | TypographyType<TypographyTags, AllTypography>
  | TypographyBlogType<TypographyTags, AllBlogTypography>) {
  const createClassTypography = (blogTypography: boolean) =>
    createClass(
      blogTypography ? TYPOGRAPHY_BLOG_PREFIX : TYPOGRAPHY_PREFIX,
      typographyKind || ''
    );

  const classNameAll = `
    position-relative
    ${className}
    ${createClass(PREFIX_MARGIN, 'v', `${getSpaceVerticalPostfix(margin)}`)}
    ${createClass(PREFIX_MARGIN, 'h', `${getSpaceHorizontalPostfix(margin)}`)}
    ${createClassTypography(blogTypography)}
    ${createClass(PREFIX_TEXT_COLOR, color)}
    ${size ? createClass(PREFIX_FONT_SIZE, `${size}`) : ''}
    ${lineHeight ? createClass(PREFIX_LINE_HEIGHT, `${lineHeight}`) : ''}
    `;

  const getRedirection = () => {
    if (isExternalLink) {
      return (
        <a
          className={`typography-link ${createClass(PREFIX_TEXT_COLOR, color)}`}
          href={link}
          target={'_blank'}
          rel="noreferrer"
        />
      );
    }

    return (
      <Link
        className={`typography-link ${createClass(PREFIX_TEXT_COLOR, color)}`}
        to={link}
      />
    );
  };

  const componentTagProps =
    blogTypography && link
      ? {
          href: link,
          target: '_blank'
        }
      : null;

  const ComponentTag = `${component}` as keyof JSX.IntrinsicElements;
  return (
    <ComponentTag id={id} className={classNameAll} {...componentTagProps}>
      {children}
      {link && !blogTypography ? getRedirection() : ''}
    </ComponentTag>
  );
}

export default memo(Typography);
