import './src/styles/vendor/_normalize.scss'; // css to normalize style
import './src/styles/base/_default.scss'; // css with default style
import './src/styles/base/_global.scss'; // css with global styles
import './src/styles/base/_fonts.scss'; // css with global fonts
import './src/styles/vendor/_grid.scss'; // grid from bootstrap
import { MDXProvider } from '@mdx-js/react';
import SimpleReactLightbox from 'simple-react-lightbox';
import Components from './src/templates/PostComponentsTemplate/PostComponentsTemplate';

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
export const wrapRootElement = ({ element }) => {
  return (
    <SimpleReactLightbox>
      <MDXProvider components={Components}>{element}</MDXProvider>
    </SimpleReactLightbox>
  );
};
